<template>
    <div class="content-top-header">
        <div class="content-header-left">
            <div class="backIcon" @click="goBack">
                <i class="iconfont guoran-tongyichicun-18-22-fanhui"></i>
            </div>
            <div class="header-title">
                <span class="header-title-text">{{headerTitle}}</span>
            </div>
            <div class="tips">
                <slot name="content-header-left-box"></slot>
            </div>
            <slot name="content-header-left-tips"></slot>
        </div>
        <!-- <div class="content-header-center">
            <slot name="content-header-center"></slot>
        </div> -->
                <div class="content-header-right">
                    <slot name="content-header-right"></slot>
                    <div class="top-header-right">
                        <div v-if="!isShowTest && !isShowHeathly" class="new-help-tips" @click="gotoHelp">
                            <i class="iconfont guoran-tongyichicun-xinshouyindao"></i>
                            <span>帮助中心</span>
                        </div>
                        <div v-if="(isShowTest && ! isShowHeathly) || (!isShowTest && isShowHeathly)" class="new-help-tips-min" @click="gotoHelp">
                            <i class="iconfont guoran-tongyichicun-xinshouyindao"></i>
                            
                        </div>
                        <!-- <div v-if="!isShowTest" class="new-help-tips" @click="gotoHelp">
                            <i class="iconfont guoran-tongyichicun-xinshouyindao"></i>
                            <span>帮助中心</span>
                        </div>
                        <div v-else class="new-help-tips-min" @click="gotoHelp">
                            <i class="iconfont guoran-tongyichicun-xinshouyindao"></i>
                        </div> -->
                    </div>
                    <div class="user-icon">
                    <el-dropdown @command="topHandleCommand">
                        <span class="el-dropdown-link">
                            <!-- <span class="head_portrait">
                                <el-avatar :size="37" :src="userInfo.profilePhoto|filterProfilePhoto"></el-avatar>
                            </span> -->
                             <el-image style="width:37px;height:37px;border-radius:50%" fit="cover" :src="userInfo.profilePhoto|filterProfilePhoto"></el-image>
                        </span>
                        <el-dropdown-menu slot="dropdown" style="min-width:150px;">
                            <el-dropdown-item
                                    style="color: black;word-break: break-word;border-bottom: 2px solid #f5f8fb;line-height: 30px">
                                {{userInfo.realName?userInfo.realName:userInfo.username?userInfo.username:""}}
                            </el-dropdown-item>
                            <el-dropdown-item command="a" class="borderBottom">
                                <i class="el-icon-user-solid" style="font-size: 14px;"></i>个人信息
                            </el-dropdown-item>
                            <!-- <el-dropdown-item command="business" class="borderBottom"
                                                v-if="roles === 1 || roles === 6">
                                <i class="el-icon-office-building"
                                    style="font-size: 14px;"></i>企业管理
                            </el-dropdown-item> -->
                            <el-dropdown-item command="logout">
                                <i class="el-icon-switch-button"
                                    style="font-size: 14px;"></i>退出登录
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
       
                </div>
                


            
    </div>
</template>

<script>
import { rediect } from "@/const/rediect";
import { redirect2LoginPage } from "@/const/redirectUri";
export default {
    props:{
		headerTitle:{
			type:String,
			default:''
		},
        isAutoBack:{
            type:Boolean,
            default:true
        },
        showHelp: {
            type:Boolean,
            default:true
        }
	},
    data(){
        return {
            isShowTest: false,
            userInfo:{},
            roles: "",
           isShowHeathly:false
        }
    },
    mounted(){
        
        this.$nextTick(()=>{
            setTimeout(()=>{
                let userInfo = localStorage.getItem('userInfo');
                if (userInfo && userInfo!=='{}'){
                    this.userInfo = JSON.parse(userInfo);
                    console.log(this.userInfo,'---');
                    this.roles = this.userInfo.roleId;
                }
            },500)
        })
        this.$eventBus.$on("test-window-visible", (visible) => {
            this.isShowTest = visible;
            if(this.isShowTest){
                this.isShowHeathly = false;
            }
        });
        this.isShowTest = sessionStorage.getItem('isOpenTestDrawer') == 'true' ? true : false;
        this.isShowHeathly = sessionStorage.getItem('isOpenHeathlyDrawer') == 'true' ? true : false;
        this.$eventBus.$on("bot-heathly-visible", (visible) => {
            this.isShowHeathly = visible.flag;
            if(this.isShowHeathly){
                this.isShowTest = false;
            }
        });
    },
    filters:{
        filterProfilePhoto(val) {
            let str = val;;
            if (val == '' || val == null || val == 'null') {
                str = "https://static.guoranbot.com/cdn-office-website/images/default_avt_ui.png"; 
            } 
            return str;
        }
    },
    
    methods:{
        goBack(){
            console.log(this.isAutoBack,111);
            if(this.isAutoBack){
                this.$router.go(-1)
            } else {
                this.$emit('goBack')
            }
        },
         topHandleCommand(command) {
            if (command === "logout") {
                localStorage.removeItem("token");
                this.FetchDelete2(this.requestUrl.auth.ssoLogout, {}).then(res => {
                    if (res.code === "0") {
                        redirect2LoginPage();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
            if (command === "a") {
                this.$router.push({ path: "personal" });
            }
            if (command === "business") {
                let windo = window.open("_blank");
                windo.location = rediect[process.env.VUE_APP_CONFIG_ENV].portal;
                windo.location = rediect[process.env.VUE_APP_CONFIG_ENV].test;
            }
            if (command === "change") {
                /* let windo= window.open('_blank')
                    windo.location = rediect[process.env.VUE_APP_CONFIG_ENV].portal;*/
                console.log(rediect[process.env.VUE_APP_CONFIG_ENV].portal);
                window.open(rediect[process.env.VUE_APP_CONFIG_ENV].portal);
            }
        },
         // 跳转到帮助页面
        gotoHelp() {
            //window.open("https://showdoc.askbot.cn/web/#/5?page_id=24");
            this.$router.push({
                name: "help",
                params: {
                    helpSrc:
                        "https://showdoc.askbot.cn/web/#/readonly/5?page_id=24",
                },
            });
        },
    }
    
}
</script>

<style lang="less" scoped>
.content-top-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    height: 62px;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
    border-radius: 5px;
    color: #000000;
    .content-header-left {
        font-size: 16px;
        display: flex;
        align-items: center;
        .backIcon {
            width: 60px;
            height: 62px;
            background: #AAB9E1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            border-radius: 5px;
            margin-right: 14px;
            cursor: pointer;
        }

        .header-title {
            display: flex;
            align-items: center;
            font-size: 15px;
            margin-right: 14px;
            .iconfont {
                padding-right: 10px;
                color: #616161;
            }
            .header-title-text{
                font-size: 15px;
                text-overflow: -o-ellipsis-lastline;
	            overflow: hidden;				//溢出内容隐藏
	            text-overflow: ellipsis;		//文本溢出部分用省略号表示
	            display: -webkit-box;			//特别显示模式
	            -webkit-line-clamp: 2;			//行数
	            line-clamp: 2;					
	            -webkit-box-orient: vertical;	//盒子中内容竖直排列
            }
        }
    }
    .content-header-center{
        display: flex;
        align-items: center;
    }
    .content-header-right{
       display: flex;
       align-items: center;
        .user-icon{
            margin-left: 10px;
            margin-top: 5px;
            cursor: pointer;
        }
        .new-help-tips-min {
            margin-left: 10px;
            width: 33px;
            height: 33px;
            background: #ffffff;
            border: 1px solid #a1b9ff;
            border-radius: 17px;
            font-size: 14px;
            color: #366aff;
            cursor: pointer;
            display: flex;
            align-items: center;
            text-align: center;
            i {
                font-size: 10px;
                color: #fff;
                margin: 0 auto;
                width: 20px;
                height: 20px;
                background: linear-gradient(224deg, #749bff, #366aff);
                border-radius: 50%;
                display: inline-block;
                line-height: 20px;
                text-align: center;
            }
        }
        .new-help-tips {
            width: 85px;
            padding: 0 10px 0 6px;
            height: 33px;
            background: #ffffff;
            border: 1px solid #a1b9ff;
            border-radius: 17px;
            font-size: 14px;
            color: #366aff;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-left: 10px;
            i {
                font-size: 14px;
                color: #fff;
                margin-right: 6px;
                width: 23px;
                height: 23px;
                background: linear-gradient(224deg, #749bff, #366aff);
                border-radius: 50%;
                display: inline-block;
                line-height: 23px;
                text-align: center;
            }
        }
    }

}


</style>